import sumBy from 'lodash/sumBy';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  CardHeader,
  Grid,
  Typography
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
// hooks
import useTabs from 'src/hooks/useTabs';
import useSettings from 'src/hooks/useSettings';
// import useTable, { getComparator, emptyRows } from "../../hooks/useTable";
// _mock_
import { _invoices } from 'src/_mock';
// components
import Page from 'src/components/Page';

import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom } from 'src/components/table';
// sections

import { EduleyPayoutReport, CommissionTableRow, InvoiceTableToolbar } from "src/sections/@dashboard/fund_management/payout";
import { getFundPayoutlist } from "src/redux/slices/fund_management";
import { useDispatch, useSelector } from "src/redux/store";
import { BankCard, PaypalCard, InteracCard } from "src/sections/@dashboard/fund_management/payout/BankCard";
import { eduleyApi } from "src/api/apiConfig";
import { EDULEY_PAYOUT, EDULEY_PAYOUT_HIGHLIGHT, FUND_TOTAL_PAYABLE } from "src/apiUrl";
import { getPageLimit, setPageLimit } from "src/utils/pageLimitControl";
import InvoiceAnalytic from "src/sections/@dashboard/fund_management/InvoiceAnalytic";
import Scrollbar from "src/components/Scrollbar";

// ----------------------------------------------------------------------

// const SERVICE_OPTIONS = ["all", "Debit", "Credit"];

const TABLE_HEAD = [
  { id: 'created_date', label: 'Date', align: 'left' },
  // { id: "invoiceNumber", label: "", align: "left" },
  // { id: "dueDate", label: "Due", align: "left" },
  { id: 'price', label: 'Amount', align: 'center' },
  { id: '', label: 'Status', align: 'left' },
  { id: '', label: 'Remarks', align: 'left' },
  { id: '', label: 'Action', align: 'left' }
];

// ----------------------------------------------------------------------

export default function CommissionPayout() {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [amountData, setAmountData] = useState({});
  const [transactions, setTransaction] = useState({});
  const [data, setData] = useState([]);
  const [pagedata, setPageData] = useState({
    limit: getPageLimit("recentpayout"),
    offset: 0,
    orderBy: 'create_date',
    order: 'desc'
  });

  const query = `?limit=${pagedata.limit}&offset=${pagedata.offset}&ordering=${pagedata.order === 'desc' ? '-' : ''}${pagedata.orderBy}`;
  // const {
  //     payout: { transactions, account, amount },
  // } = useSelector((state) => state.fund_management);
  // console.log(transactions, account);
  // // ----------------------------------------------------------------------

  useEffect(() => {
    getFundPayoutlist(query);
    // dispatch(getFundPayoutAccount("?offset=0"));
    getFundPayoutAmount();
  }, [dispatch]);

  useEffect(() => {
    getFundPayoutlist(query);
  }, [pagedata]);
  useEffect(() => {
    gethightlight();
  }, []);
  // pay to eduley highlight
  const gethightlight = async () => {
    // setLoading(true);
    try {
      let res = await eduleyApi.get(`${FUND_TOTAL_PAYABLE}`);
      console.log('total--', res.data);
      setData(res.data);
    } catch (error) { }
  };
  const getFundPayoutAmount = async () => {
    try {
      let res = await eduleyApi.get(EDULEY_PAYOUT_HIGHLIGHT);
      console.log(res.data[0]);
      setAmountData(res.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  const getFundPayoutlist = async () => {
    try {
      let res = await eduleyApi.get(`${EDULEY_PAYOUT}${query}`);
      console.log(res);
      setTransaction(res.data);
      setTableData(res.data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOrderBy = (event) => {
    console.log('ordering===', event);
    setPageData({
      ...pagedata,
      orderBy: event,
      offset: 0,
      order: pagedata.orderBy === event && pagedata.order === 'desc' ? 'asc' : 'desc'
    });
  };
  let colorindex = [theme.palette.info.main, theme.palette.warning.main, theme.palette.success.main];
  return (
    <>
      <Page title="Fund Payouts">
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs heading="Pay To Eduley" links={[{ name: '', href: '' }]} />

          <Stack sx={{ mb: 2 }}>
            {amountData?.data?.data <= 0 && (
              <Typography>
                You do <b> not owe any payable amount</b> to Eduley
              </Typography>
            )}
          </Stack>
          <Card sx={{ mb: 2, width: '60%' }}>
            <Scrollbar>
              <Stack direction="row" divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />} sx={{ py: 2 }}>
                {data.map((a, i) => (
                  <>
                    {i != 2 ? (
                      <InvoiceAnalytic
                        title={a.data.heading}
                        price={a.data.data.amount}
                        percent={100}
                        total={''}
                        icon={a.data.icon}
                        color={colorindex[i]}
                      // action={<BreackUpStatement />}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ))}
              </Stack>
            </Scrollbar>
          </Card>

          <Stack spacing={2} direction={{ sm: 'column', md: 'row' }}>
            <Card sx={{ mb: 5 }}>
              <Stack direction="row">
                <EduleyPayoutReport
                  amount={amountData}
                  donepayment={() => {
                    getFundPayoutAmount();
                    getFundPayoutlist(query);
                    gethightlight();
                  }}
                />
              </Stack>
            </Card>

            <Stack>
              <Card>
                <CardHeader
                  title="Recent Payouts"
                  sx={{
                    '& .MuiCardHeader-action': {
                      alignSelf: 'center',
                      mb: 2
                    }
                  }}
                />
                <TableContainer
                  sx={{
                    minWidth: 800,
                    position: 'relative',
                    minHeight: 360,
                    mt: 1
                  }}
                >
                  <Table>
                    <TableHeadCustom
                      order={pagedata.order}
                      orderBy={pagedata.orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={tableData?.length}
                      onSort={handleOrderBy}
                    />

                    <TableBody>
                      {tableData?.map((row, ii) => (
                        <CommissionTableRow
                          key={ii + 'hjj'}
                          row={row}
                          recalllist={() => {
                            getFundPayoutlist();
                          }}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* </Scrollbar> */}

                <Box sx={{ position: 'relative' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={transactions?.count || 0}
                    rowsPerPage={pagedata.limit}
                    page={pagedata.offset / pagedata.limit + 0}
                    onPageChange={(event, newPage) => {
                      // console.log("newPage====", newPage);
                      setPageData({
                        ...pagedata,
                        offset: newPage * pagedata.limit
                      });
                    }}
                    onRowsPerPageChange={(event) => {
                      // console.log("event====", event.target.value);
                      setPageLimit('recentpayout', event.target.value);
                      setPageData({ ...pagedata, limit: event.target.value });
                    }}
                  />
                </Box>
              </Card>
            </Stack>
          </Stack>
        </Container>
      </Page>
    </>
  );
}

// ----------------------------------------------------------------------
