import sumBy from 'lodash/sumBy';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  CardHeader,
  Grid,
  Typography
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
// hooks
import useTabs from 'src/hooks/useTabs';
import useSettings from 'src/hooks/useSettings';
// import useTable, { getComparator, emptyRows } from "../../hooks/useTable";
// _mock_
import { _invoices } from 'src/_mock';
// components
import Page from 'src/components/Page';

import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom } from 'src/components/table';
// sections

import { PayoutReport, InvoiceTableRow, InvoiceTableToolbar, AddBankCard } from "src/sections/@dashboard/fund_management/payout";
import { getFundPayoutAccount, getFundPayoutAmount, getFundPayoutlist } from "src/redux/slices/fund_management";
import { useDispatch, useSelector } from "src/redux/store";
import { BankCard, PaypalCard, InteracCard } from "src/sections/@dashboard/fund_management/payout/BankCard";
import { getPageLimit, setPageLimit } from "src/utils/pageLimitControl";
import Scrollbar from "src/components/Scrollbar";
import InvoiceAnalytic from "src/sections/@dashboard/fund_management/InvoiceAnalytic";
import { eduleyApi } from "src/api/apiConfig";
import { FUND_TOTAL_RECEIVABLE } from "src/apiUrl";

// ----------------------------------------------------------------------

// const SERVICE_OPTIONS = ["all", "Debit", "Credit"];

const TABLE_HEAD = [
  { id: 'created_date', label: 'Date', align: 'left' },
  // { id: "invoiceNumber", label: "", align: "left" },
  // { id: "dueDate", label: "Due", align: "left" },
  { id: 'price', label: 'Amount', align: 'center' },
  { id: '', label: 'Status', align: 'left' },
  { id: '', label: 'Remarks by Eduley Team', align: 'left', width: 240 },
  { id: '' }
];

// ----------------------------------------------------------------------

export default function InstitutePayout() {
  const { themeStretch } = useSettings();
  const theme = useTheme();

  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [amountData, setAmountData] = useState([]);
  const [data, setData] = useState([]);

  const [pagedata, setPageData] = useState({
    limit: getPageLimit("recentpayout"),
    offset: 0,
    orderBy: 'create_date',
    order: 'desc'
  });

  const query = `?limit=${pagedata.limit}&offset=${pagedata.offset}&ordering=${pagedata.order === 'desc' ? '-' : ''}${pagedata.orderBy}`;
  const {
    payout: { transactions, account, amount }
  } = useSelector((state) => state.fund_management);
  console.log(transactions, account);
  // ----------------------------------------------------------------------

  useEffect(() => {
    dispatch(getFundPayoutlist(query));
    dispatch(getFundPayoutAccount('?offset=0'));
    dispatch(getFundPayoutAmount());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getFundPayoutlist(query));
  }, [pagedata]);

  useEffect(() => {
    setTableData(transactions.results);
  }, [transactions]);

  useEffect(() => {
    gethightlight();
  }, []);
  // pay to eduley highlight
  const gethightlight = async () => {
    // setLoading(true);
    try {
      let res = await eduleyApi.get(`${FUND_TOTAL_RECEIVABLE}`);

      console.log('total--', res.data);
      setData(res.data);
    } catch (error) { }
  };

  useEffect(() => {
    setAccountData(account.results);
  }, [account]);
  useEffect(() => {
    console.log('accountData', amount);
    setAmountData(amount);
  }, [amount]);
  const handleOrderBy = (event) => {
    console.log('ordering===', event);
    setPageData({
      ...pagedata,
      orderBy: event,
      offset: 0,
      order: pagedata.orderBy === event && pagedata.order === 'desc' ? 'asc' : 'desc'
    });
  };
  let colorindex = [theme.palette.info.main, theme.palette.warning.main, theme.palette.success.main];

  return (
    <>
      <Page title="Fund Payouts">
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs heading="My Fund Payouts" links={[{ name: '', href: '' }]} />
          {amountData[0]?.data?.data <= 0 && (
            <Typography sx={{ mb: 2 }}>
              You have <b>no receivable amount</b> from Eduley
            </Typography>
          )}
          <Card sx={{ mb: 2, width: '60%' }}>
            <Scrollbar>
              <Stack direction="row" divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />} sx={{ py: 2 }}>
                {data?.map((a, i) => (
                  <>
                    {i != 2 && (
                      <InvoiceAnalytic
                        title={a?.data?.heading}
                        price={a?.data?.data?.amount}
                        percent={100}
                        total={''}
                        icon={a?.data?.icon}
                        color={colorindex[i]}
                      // action={<BreackUpStatement />}
                      />
                    )}
                  </>
                ))}
              </Stack>
            </Scrollbar>
          </Card>

          <Stack spacing={2} direction={{ sm: 'column', md: 'row' }}>
            <Stack>
              <Card sx={{ mb: 5 }}>
                <Stack direction="row">
                  <PayoutReport
                    amount={amountData[0]}
                    donepayment={() => {
                      dispatch(getFundPayoutAmount());
                      dispatch(getFundPayoutlist(query));
                      gethightlight();
                    }}
                  />
                </Stack>
              </Card>
            </Stack>
            <Stack>
              <Card>
                <CardHeader
                  title="Recent Payouts"
                  sx={{
                    '& .MuiCardHeader-action': {
                      alignSelf: 'center',
                      mb: 2
                    }
                  }}
                />
                <TableContainer sx={{ minWidth: 800, position: 'relative', minHeight: 360 }}>
                  <Table>
                    <TableHeadCustom
                      order={pagedata.order}
                      orderBy={pagedata.orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={tableData?.length}
                      onSort={handleOrderBy}
                    />

                    <TableBody>
                      {tableData?.map((row, ii) => (
                        <InvoiceTableRow
                          key={ii + 'hjj'}
                          row={row}
                          selected={false}
                          onSelectRow={() => { }}
                          onViewRow={() => { }}
                          onEditRow={() => { }}
                          onDeleteRow={() => { }}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* </Scrollbar> */}

                <Box sx={{ position: 'relative' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={transactions?.count || 0}
                    rowsPerPage={pagedata.limit}
                    page={pagedata.offset / pagedata.limit + 0}
                    onPageChange={(event, newPage) => {
                      // console.log("newPage====", newPage);
                      setPageData({
                        ...pagedata,
                        offset: newPage * pagedata.limit
                      });
                    }}
                    onRowsPerPageChange={(event) => {
                      // console.log("event====", event.target.value);
                      setPageLimit('recentpayout');
                      setPageData({ ...pagedata, limit: event.target.value });
                    }}
                  />
                </Box>
              </Card>
            </Stack>
          </Stack>
          <Grid container spacing={2}>
            {accountData?.map((a, i) => (
              <>
                {a.beneficiary_account_number && (
                  <Grid item xs={12} sm={12} md={4} key={'acc' + i}>
                    <BankCard
                      data={a}
                      updateCard={() => {
                        dispatch(getFundPayoutAccount('?offset=0'));
                      }}
                    />
                  </Grid>
                )}
                {a.paypal && (
                  <Grid item xs={12} sm={12} md={4} key={'acc' + i}>
                    <PaypalCard
                      data={a}
                      updateCard={() => {
                        dispatch(getFundPayoutAccount('?offset=0'));
                      }}
                    />
                  </Grid>
                )}
                {a.interac && (
                  <Grid item xs={12} sm={12} md={4} key={'acc' + i}>
                    <InteracCard
                      data={a}
                      updateCard={() => {
                        dispatch(getFundPayoutAccount('?offset=0'));
                      }}
                    />
                  </Grid>
                )}
              </>
            ))}
            <Grid item xs={12} md={4}>
              <AddBankCard
                updateCard={() => {
                  dispatch(getFundPayoutAccount('?offset=0'));
                }}
                done={() => {
                  dispatch(getFundPayoutAccount('?offset=0'));
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}

// ----------------------------------------------------------------------
