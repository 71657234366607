import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";
import { eduleyApi } from "src/api/apiConfig";
import { COHORT_ATTENDANCE, INSTRUCTOR_ANALYTICS } from "src/apiUrl";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  attendanceListForCohort: {
    isLoading: false,
    data: [],
    totalCount: 0,
    error: null,
  },
  attendanceForCohort: {
    isLoading: false,
    data: null,
    error: null,
  },
  attendanceForInstructor: {
    isLoading: false,
    data: null,
    error: null,
  },
};

const slice = createSlice({
  name: "attendance",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startLoadingCohortAttendanceList(state) {
      state.attendanceListForCohort.isLoading = true;
    },
    startLoadingCohortAttendance(state) {
      state.attendanceForCohort.isLoading = true;
    },
    startLoadingInstructorAttendance(state) {
      state.attendanceForInstructor.isLoading = true;
    },
    hasErrorCohortAttendanceList(state, action) {
      state.attendanceListForCohort.isLoading = false;
      state.attendanceListForCohort.error = action.payload;
    },
    hasErrorCohortAttendance(state, action) {
      state.attendanceForCohort.isLoading = false;
      state.attendanceForCohort.error = action.payload;
    },
    hasErrorInstructorAttendance(state, action) {
      state.attendanceForInstructor.isLoading = false;
      state.attendanceForInstructor.error = action.payload;
    },
    resetCohortForAttendance(state) {
      state.attendanceForCohort = initialState.attendanceForCohort;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },
    getCohortAttendanceListSuccess(state, action) {
      state.attendanceListForCohort.isLoading = false;
      state.attendanceListForCohort = action.payload;
      state.attendanceListForCohort.error = null;
    },
    getCohortAttendanceSuccess(state, action) {
      state.attendanceForCohort.isLoading = false;
      state.attendanceForCohort = action.payload;
      state.error = null;
    },
    getInstructorAttendanceSuccess(state, action) {
      state.attendanceForInstructor.isLoading = false;
      state.attendanceForInstructor = action.payload;
      state.attendanceForInstructor.error = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { resetCohortForAttendance, getCohortAttendanceSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getEvents() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/calendar/events");
      dispatch(slice.actions.getEventsSuccess(response.data.events));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

/**
 * Retrieves a list of cohort attendance records.
 *
 * @param {Object} params - The parameters to filter the attendance records.
 * @returns {Function} - An async function that dispatches actions to retrieve the attendance records.
 */
export function getListOfCohortAttendance(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingCohortAttendanceList());

    try {
      const response = await eduleyApi.get(COHORT_ATTENDANCE, { params });

      const payload = {
        totalCount: response.data.count,
        data: response.data.results,
      };

      dispatch(slice.actions.getCohortAttendanceListSuccess(payload));
    } catch (error) {
      dispatch(slice.actions.hasErrorCohortAttendanceList(error));
    }
  };
}
/**
 * Retrieves the attendance of a cohort by its ID.
 *
 * @param {string} date - The Date of the cohort.
 * @param {object} params - Additional parameters for the API request.
 * @returns {Promise} A promise that resolves with the attendance data.
 */
export function getCohortAttendanceByDate(cohortId, date, params) {
  return async () => {
    // Start loading the cohort attendance list
    dispatch(slice.actions.startLoadingCohortAttendance());

    try {
      // Make API request to retrieve the attendance data
      const response = await eduleyApi.get(`${COHORT_ATTENDANCE}${cohortId}/${date}/`, { params });

      // Prepare the payload with the response data
      const payload = {
        data: response.data,
      };

      // Dispatch the success action with the payload
      dispatch(slice.actions.getCohortAttendanceSuccess(payload));
    } catch (error) {
      // Dispatch the error action with the error object
      dispatch(slice.actions.hasErrorCohortAttendance(error.data.data));
    }
  };
}

// ----------------------------------------------------------------------

export function getInstructorAttendanceList(params) {
  return async () => {
    dispatch(slice.actions.startLoadingInstructorAttendance());
    try {
      const response = await eduleyApi.get(INSTRUCTOR_ANALYTICS, { params });
      const payload = {
        totalCount: response.data.count,
        data: response.data.results,
      };
      dispatch(slice.actions.getInstructorAttendanceSuccess(payload));
    } catch (error) {
      dispatch(slice.actions.hasErrorInstructorAttendance(error));
    }
  };
}
