import PropTypes from 'prop-types';
import { Container, Alert, AlertTitle } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { useLocation } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useSelector } from 'react-redux';
import useLanding from 'src/hooks/useLanding';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array, // Example ['admin', 'leader']
  children: PropTypes.node
};

const useCurrentRole = () => {
  // Logic here to get current user role
  const role = 'admin';
  return role;
};

export default function RoleBasedGuard({ accessibleRoles, children, noPermissionChildren }) {
  const currentRole = useCurrentRole();
  const { user, instituteinfo } = useAuth();
  const { hasCohortAttendance } = useLanding();

  const location = useLocation();
  const getpermit = (perm, gr) => {
    let d = perm;
    let pr = false;
    let p = gr?.forEach((element) => {
      if (pr === false) {
        pr = d?.includes(element);
        console.log(d?.includes(element));
      }
    });
    return pr;
  };

  const authorizedPath = () => {
    let _auth = false;
    if (location.pathname === PATH_DASHBOARD.cohortAttendance.list && !instituteinfo?.enable_cohort_attendance) {
      _auth = true;
    }
    return _auth;
  };

  console.log('permission------', accessibleRoles, user.groups, location.pathname, authorizedPath());
  if (!getpermit(accessibleRoles, user?.groups) || authorizedPath()) {
    if (noPermissionChildren) {
      return <>{noPermissionChildren}</>
    }

    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
