import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
//
import { NavListRoot } from './NavList';
import useAuth from 'src/hooks/useAuth';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useSelector } from 'react-redux';
import useLanding from 'src/hooks/useLanding';
import { getAccessibility } from 'src/utils/common';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter
  })
}));

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array
};

export default function NavSectionVertical({ navConfig, isCollapse = false, groups, ...other }) {
  const { instituteinfo } = useAuth();
  const { hasCohortAttendance } = useLanding();
  function filteredMenu(menu) {
    if (instituteinfo?.enable_cohort_attendance) {
      return menu;
    } else {
      return menu.filter((item) => item.path !== PATH_DASHBOARD.cohortAttendance.list);
    }
  }

  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          {getAccessibility(group?.permission, groups) && <>
            <ListSubheaderStyle
              sx={{
                ...(isCollapse && {
                  opacity: 0
                })
              }}
            >
              {group.subheader}
            </ListSubheaderStyle>

            {filteredMenu(group.items).map((list) => (
              <NavListRoot key={list.title} list={list} isCollapse={isCollapse} groups={groups} />
            ))}
          </>}
        </List>
      ))}
    </Box>
  );
}
