import React from 'react'
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// @mui
import {
    Container, Card, Table, Tooltip, TableBody, IconButton, TableContainer, InputAdornment,
    TablePagination, TableCell, TableRow, Divider, Typography, MenuItem, TextField, Select, CardContent, Stack, Grid, Button, Dialog, DialogContent, DialogActions, DialogTitle
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// redux
import { useDispatch } from 'src/redux/store';
import { getLabels } from 'src/redux/slices/mail';
// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
// hooks
import useSettings from 'src/hooks/useSettings';
// components
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions, TableSkeleton } from 'src/components/table';
import Iconify from 'src/components/Iconify';
import { emptyRows } from 'src/hooks/useTable';
import { data } from './data';
import { eduleyApi } from 'src/api/apiConfig';
import { PROFILE_COMPLETION_CONFIGURATION, PROFILE_COMPLETION_CONFIGURATION_FIELD } from 'src/apiUrl';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';
import { fNumber2decimal } from 'src/utils/formatNumber';
const TABLE_HEAD = [
    { id: 'name', label: 'Available Field', align: 'left' },
    { id: 'file_extension_type', label: 'Verification Type', align: 'left' },
    { id: 'max_file_size', label: 'Weightage value', align: 'left' },
    { id: '' }
];

const rowSx = {
    borderRadius: 1,
    '& .MuiTableCell-root': {
        bgcolor: 'background.default'
    },
    '& .MuiTableCell-body:first-of-type': {
        borderTopLeftRadius: '12px',
        borderBottomLeftRadius: '12px',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        borderLeft: (theme) => `1px solid ${theme.palette.divider}`
    },
    '& .MuiTableCell-body:last-of-type': {
        borderTopRightRadius: '12px',
        borderBottomRightRadius: '12px',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        borderRight: (theme) => `1px solid ${theme.palette.divider}`
    },
    '& .MuiTableCell-body': {
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`
    },

}
const ProfileEnrichment = () => {
    const { themeStretch } = useSettings();
    const [loading, setLoading] = useState(false)
    const { instituteinfo } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [availableItem, setAvailableItem] = useState([])
    const [data, setData] = useState([])
    const [update, setUpdate] = useState(true);
    const tableData = []
    useEffect(() => {

        getConfigurationField()
    }, [])

    const getProfileCompletionData = async (item) => {
        try {
            let res = await eduleyApi.get(`${PROFILE_COMPLETION_CONFIGURATION}`)
            console.log('res---', res.data)
            let data = res.data.map(a => ({ ...a, weightage: fNumber2decimal(a.weightage) }))
            setData(data)
            setUpdate(!update)
            customizeAvailableField(res.data, item)
        } catch (error) {
            console.log('error', error)
        }
    }
    const getConfigurationField = async () => {
        try {
            let res = await eduleyApi.get(`${PROFILE_COMPLETION_CONFIGURATION_FIELD}`)
            console.log('res---', res.data)
            setAvailableItem(res.data)
            getProfileCompletionData(res.data)
        } catch (error) {
            console.log('error', error)
        }
    }
    const customizeAvailableField = (item, item2) => {
        let available = []
        let p = item?.map(a => a.field_name)
        console.log('p', p)
        item2.forEach((item) => {
            if (!p.includes(item.field_name)) {
                available.push(item)
            }
        })

        console.log('available', available, availableItem, item2)

        setAvailableItem(available)
    }
    const handleChange = (item, index) => {
        let a = [...data]
        a[index] = item
        setData(a)
        // setUpdate(!update)

    }
    const handleAddNewField = (item) => {
        setData([...data, item])
        setUpdate(!update)

        customizeAvailableField([...data, item], availableItem)
    }
    const handleDelete = (a) => {
        let info = data.filter((item) => item.field_name !== a.field_name)
        setData(info)
        setUpdate(!update)
        let available = [...availableItem, { field_name: a.field_name, institute_configuration_id: a?.id || null }]
        console.log('available', available)
        customizeAvailableField(info, available)
    }
    const handleSave = async () => {
        // check weightage should not be 0
        let check = data.filter((item) => +item.weightage <= 0)
        if (check.length > 0) {
            enqueueSnackbar('Weightage should be greater than 0', { variant: 'error' })
            return
        }
        setLoading(true)
        try {
            let res = await eduleyApi.patch(`${PROFILE_COMPLETION_CONFIGURATION}${instituteinfo.id}/`, data)
            enqueueSnackbar('Updated successfully')
            getProfileCompletionData(availableItem)
        } catch (error) {
            console.log('error', error)
            if (error?.data?.data) {
                enqueueSnackbar(error?.data?.data?.non_fields_errors, { variant: 'error' })
            }
        }
        setLoading(false)
    }
    console.log('availableItem', availableItem)
    return (
        <>
            <Page title="STUDENT PROFILE ENRICHMENT">
                <Container maxWidth={themeStretch ? false : 'xl'}>
                    <HeaderBreadcrumbs
                        heading="Student Profile Enrichment"
                        links={[{ name: '', },]}
                        action={
                            availableItem.length > 0 && <AvailableField item={availableItem} handleAdd={handleAddNewField} />
                        }
                        subHeading={<Typography variant='body2' sx={{ color: 'primary.main' }} >How can users complete their profiles up to 100%?</Typography>}
                    />
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                            <Card sx={{ display: { md: 'flex' }, boxShadow: 'none', }}>
                                <TableContainer>
                                    <Table

                                        sx={{
                                            minWidth: 600,
                                            borderCollapse: 'separate',
                                            borderSpacing: '0 8px',
                                            '& .MuiTableCell-head': {
                                                boxShadow: 'none !important',
                                                borderRadius: 0,
                                                '&:first-of-type': {
                                                    borderTopLeftRadius: '12px',
                                                    borderBottomLeftRadius: '12px'
                                                },
                                                '&:last-of-type': {
                                                    borderTopRightRadius: '12px',
                                                    borderBottomRightRadius: '12px'
                                                }
                                            }
                                        }}
                                    >

                                        <TableHeadCustom

                                            headLabel={TABLE_HEAD}
                                            rowCount={tableData.length}

                                            sx={{
                                                '& .MuiTableCell-head': {
                                                    // bgcolor: 'background.neutral'
                                                },
                                                '.MuiTableHead-root': {
                                                    borderRadius: `12px !important`
                                                }
                                            }}
                                        />

                                        {update && <TableBody>

                                            {data?.map((a, index) => <TableRowOfFieldData
                                                item={a}
                                                key={a.id + '908833'}
                                                handleChange={value => handleChange({ ...a, ...value }, index)}
                                                handleDelete={() => handleDelete(a)} />)}
                                        </TableBody>}
                                        {!update && <TableBody>

                                            {data?.map((a, index) => <TableRowOfFieldData
                                                item={a}
                                                key={a.id + '908833'}
                                                handleChange={value => handleChange({ ...a, ...value }, index)}
                                                handleDelete={() => handleDelete(a)} />)}
                                        </TableBody>}
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <WeightageCalculation data={data} />
                            <Stack direction={'row'} spacing={2} justifyContent={'space-between'} sx={{ mt: 3 }}>
                                <LoadingButton variant="contained" size='large' fullWidth color="primary" loading={loading} onClick={() => handleSave()}>Save Changes</LoadingButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Page>
        </>
    )
}

export default ProfileEnrichment


const TableRowOfFieldData = ({ item, handleChange, handleDelete }) => {

    return <TableRow sx={rowSx} >
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>{item?.field_name?.split('_')?.join(' ')}</TableCell>
        <TableCell align="left">
            <TextField
                size="small"
                value={item.criteria}
                onChange={(e) => handleChange({ ...item, criteria: e.target.value })}
                sx={{ width: 150 }}
                select>
                <MenuItem value="success">No Verification Needed </MenuItem>
                <MenuItem value="verified">Needs Verification</MenuItem>
            </TextField>
        </TableCell>
        <TableCell align="left">
            <TextField
                size="small"
                value={item.weightage}
                onChange={(e) => handleChange({ ...item, weightage: e.target.value })}
                type='number'
                sx={{ width: 150 }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                inputProps={{
                    min: 0
                }}

            />
        </TableCell>
        <TableCell align="left">
            <IconButton
                onClick={handleDelete}
                sx={{ color: 'error.main' }}
                size="medium"
                color="inherit"
                aria-label="delete"

            >
                <Iconify icon={'fluent:delete-16-filled'} />
            </IconButton>
        </TableCell>
    </TableRow>
}

const AvailableField = ({ item, handleAdd }) => {
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState({ name: '_' })
    const { enqueueSnackbar } = useSnackbar();
    const handleAdding = (value) => {
        if (value?.name == '_') {
            enqueueSnackbar('Field name is required', { variant: 'error' })
            return
        }

        handleAdd({
            field_name: value.name,
            criteria: 'success',
            weightage: 1,
            id: value?.institute_configuration_id || null
        })
    }

    return <>
        <Button variant="outlined" size='large' fullWidth color="primary" onClick={() => setOpen(true)}>Add New Field</Button>
        <Dialog sx={rowSx} open={open} fullWidth maxWidth="sm" onClose={() => setOpen(false)}>
            <DialogTitle>Add New Field</DialogTitle>
            <DialogContent>
                <Select
                    size="large"
                    placeholder='Enter field name'
                    value={value.name || ''}
                    defaultValue={value.name}
                    onChange={(e) => setValue({ ...value, name: e.target.value, institute_configuration_id: item.find(a => a.field_name == e.target.value).institute_configuration_id || null })}
                    sx={{ mt: 2, textTransform: 'capitalize' }}
                    fullWidth
                >
                    <MenuItem value={'_'} disabled color='text.secondary'>Select new field</MenuItem>
                    {item.map(a => <MenuItem value={a.field_name} sx={{ textTransform: 'capitalize' }}>{a?.field_name?.split('_')?.join(' ')}</MenuItem>)}

                </Select>



            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} variant="outlined" size='large'>Cancel</Button>
                <Button variant="contained" size='large' onClick={() => {
                    handleAdding(value)
                    setOpen(false)
                }}>Add</Button>
            </DialogActions>
        </Dialog>
    </>
}


const WeightageCalculation = ({ data }) => {
    return <Card sx={{ height: 200 }}>
        <CardContent>
            <Typography variant="body2" sx={{ fontSize: 34, fontWeight: "bold" }} color="text.secondary">
                {data.reduce((a, b) => a + +b?.weightage, 0)}%
            </Typography>
            <Stack sx={{ mt: 2 }} />
            <Typography variant="subtitle" >Total Weightage</Typography>
            <Typography variant="subtitle2" color={'warning.main'} sx={{ mt: 2 }}>Before saving, you should ensure that the weightage is 100%.</Typography>
        </CardContent>
    </Card>
}