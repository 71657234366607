import isString from "lodash/isString";
import PropTypes from "prop-types";
// @mui
import { Box, Typography, Link } from "@mui/material";
//
import Breadcrumbs from "./Breadcrumbs";

// ----------------------------------------------------------------------

HeaderBreadcrumbs.propTypes = {
  links: PropTypes.array,
  action: PropTypes.node,
  heading: PropTypes.string.isRequired,
  moreLink: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sx: PropTypes.object,
};

export default function HeaderBreadcrumbs({ links, action, heading, subHeading, description, moreLink = "" || [], sx, ...other }) {
  return (
    <Box sx={{ mb: 5, ...sx }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant='h4' gutterBottom color='grey.800'>
            {heading}
          </Typography>
          {subHeading && (
            <Typography variant='subtitle1' color='text.secondary' gutterBottom>
              {subHeading}
            </Typography>
          )}
          {description && (
            <Typography variant='body1' color='text.secondary' gutterBottom>
              {description}
            </Typography>
          )}
          <Breadcrumbs links={links} {...other} />
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>

      <Box sx={{ mt: 2 }}>
        {isString(moreLink) ? (
          <Link href={moreLink} target='_blank' rel='noopener' variant='body2'>
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link noWrap key={href} href={href} variant='body2' target='_blank' rel='noopener' sx={{ display: "table" }}>
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}
