import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";
import { eduleyApi } from "src/api/apiConfig";
import {
  ANALYTICS_ASSIGNMENT_HIGHLIGHT,
  ANALYTICS_ASSIGNMENT_OVERVIEW,
  ANALYTICS_COURSE_HIGHLIGHT,
  ANALYTICS_COURSE_OVERVIEW,
  ANALYTICS_QUIZ_HIGHLIGHT,
  ANALYTICS_QUIZ_OVERVIEW,
  COHORT_ATTENDANCE_ANALYTICS,
  COHORT_ATTENDANCE_ANALYTICS_HIGHLIGHTS,
  COHORT_ATTENDANCE_REPORT_ATTENDANCE_BY_DAYS_OF_WEEKS,
  COHORT_ATTENDANCE_REPORT_ATTENDANCE_BY_RANGE,
} from "src/apiUrl";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  loading: {
    coursehighlightLoading: false,
    quizhighlightLoading: false,
    assignhighlightLoading: false,
    courseOverviewLoading: false,
    quizOverviewLoading: false,
    assignOverviewLoading: false,
  },
  courseOverview: {
    isLoading: false,

    course: {
      highlight: [],
      overview: {},
      loading: false,
    },
    quiz: {
      highlight: [],
      overview: {},
      loading: false,
    },
    assignment: {
      highlight: [],
      overview: {},
      loading: false,
    },
  },
  /*********************
   * COHORT ATTENDANCE *
   *********************/
  cohortAttendance: {
    analytics: {
      list: {
        data: [],
        isLoading: false,
        error: null,
        totalCount: 0,
      },
      highlights: {
        data: [],
        isLoading: false,
        error: null,
      },
    },
    report: {
      cohort: {
        highlights: {
          data: [],
          isLoading: false,
          error: null,
        },
        dateRange: {
          data: [],
          isLoading: false,
          error: null,
        },
        daysOfWeek: {
          data: [],
          isLoading: false,
          error: null,
        },
      },
      student: {
        highlights: {
          data: [],
          isLoading: false,
          error: null,
        },
        dateRange: {
          data: [],
          isLoading: false,
          error: null,
        },
        daysOfWeek: {
          data: [],
          isLoading: false,
          error: null,
        },
      },
    },
  },
};

const slice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      state.isLoading = true;
      state.loading = { ...state.loading, ...action.payload };
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },

    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },
    getanalyticsAsngHighlightSuccess(state, action) {
      state.courseOverview.assignment.highlight = action.payload;
      state.loading = { ...state.loading, assignhighlightLoading: false };
    },
    getanalyticsAsngOverviewSuccess(state, action) {
      state.courseOverview.assignment.overview = action.payload;
      state.loading = { ...state.loading, assignOverviewLoading: false };
    },
    getanalyticsQuizHighlightSuccess(state, action) {
      state.courseOverview.quiz.highlight = action.payload;
      state.loading = { ...state.loading, quizhighlightLoading: false };
    },
    getanalyticsQuizOverviewSuccess(state, action) {
      state.courseOverview.quiz.overview = action.payload;
      state.loading = { ...state.loading, quizOverviewLoading: false };
    },
    // getanalyticsCourseHighlightSuccess(state, action) {
    //   state.loading.coursehighlightLoading = false;
    //   state.courseOverview.course.highlight = action.payload;
    // },
    getanalyticsCourseHighlightSuccess(state, action) {
      state.loading.coursehighlightLoading = false;
      state.courseOverview.course.highlight = action.payload;
    },

    /************************************
     * COHORT ATTENDANCE ANALYTICS LIST *
     ************************************/
    startLoadingCohortAttendanceAnalyticsList(state) {
      state.cohortAttendance.analytics.list.isLoading = true;
    },
    getCohortAttendanceAnalyticsListSuccess(state, action) {
      state.cohortAttendance.analytics.list.data = action.payload.data;
      state.cohortAttendance.analytics.list.totalCount = action.payload.totalCount;
      state.cohortAttendance.analytics.list.isLoading = false;
      state.cohortAttendance.analytics.list.error = null;
    },
    getCohortAttendanceAnalyticsListHasError(state, action) {
      state.cohortAttendance.analytics.list.error = action.payload;
      state.cohortAttendance.analytics.list.isLoading = false;
    },
    /************************************
     * COHORT ATTENDANCE ANALYTICS HIGHLIGHTS *
     ************************************/
    startLoadingCohortAttendanceAnalyticsHighlights(state) {
      state.cohortAttendance.analytics.highlights.isLoading = true;
    },
    getCohortAttendanceAnalyticsHighlightsSuccess(state, action) {
      state.cohortAttendance.analytics.highlights.data = action.payload;
      state.cohortAttendance.analytics.highlights.isLoading = false;
      state.cohortAttendance.analytics.highlights.error = null;
    },
    getCohortAttendanceAnalyticsHighlightsHasError(state, action) {
      state.cohortAttendance.analytics.highlights.error = action.payload;
      state.cohortAttendance.analytics.highlights.isLoading = false;
    },
    /**************************************************
     * COHORT ATTENDANCE REPORT FOR COHORT HIGHLIGHTS *
     **************************************************/
    startLoadingCohortAttendanceReportCohortHighlights(state) {
      state.cohortAttendance.report.cohort.highlights.isLoading = true;
    },
    getCohortAttendanceReportCohortHighlightsSuccess(state, action) {
      state.cohortAttendance.report.cohort.highlights.data = action.payload;
      state.cohortAttendance.report.cohort.highlights.isLoading = false;
      state.cohortAttendance.report.cohort.highlights.error = null;
    },
    getCohortAttendanceReportCohortHighlightsHasError(state, action) {
      state.cohortAttendance.report.cohort.highlights.error = action.payload;
      state.cohortAttendance.report.cohort.highlights.isLoading = false;
    },

    /***************************************************
     * COHORT ATTENDANCE REPORT FOR STUDENT HIGHLIGHTS *
     ***************************************************/

    startLoadingCohortAttendanceReportStudentHighlights(state) {
      state.cohortAttendance.report.student.highlights.isLoading = true;
    },
    getCohortAttendanceReportStudentHighlightsSuccess(state, action) {
      state.cohortAttendance.report.student.highlights.data = action.payload;
      state.cohortAttendance.report.student.highlights.isLoading = false;
      state.cohortAttendance.report.student.highlights.error = null;
    },
    getCohortAttendanceReportStudentHighlightsHasError(state, action) {
      state.cohortAttendance.report.student.highlights.error = action.payload;
      state.cohortAttendance.report.student.highlights.isLoading = false;
    },
    resetCohortAttendanceReport(state) {
      state.cohortAttendance.report = {
        ...initialState.cohortAttendance.report,
      };
    },

    /******************************************
     * COHORT ATTENDANCE GRAPH FOR DATE RANGE *
     ******************************************/

    startLoadingDateRangeGraphForCohort(state) {
      state.cohortAttendance.report.cohort.dateRange.isLoading = true;
    },
    getDateRangeGraphForCohortSuccess(state, action) {
      state.cohortAttendance.report.cohort.dateRange.data = action.payload;
      state.cohortAttendance.report.cohort.dateRange.isLoading = false;
      state.cohortAttendance.report.cohort.dateRange.error = null;
    },
    getDateRangeGraphForCohortHasError(state, action) {
      state.cohortAttendance.report.cohort.dateRange.error = action.payload;
      state.cohortAttendance.report.cohort.dateRange.isLoading = false;
    },
    /********************************************
     * COHORT ATTENDANCE GRAPH FOR DAYS OF WEEK *
     ********************************************/
    startLoadingDaysOfTheWeekGraphForCohort(state) {
      state.cohortAttendance.report.cohort.daysOfWeek.isLoading = true;
    },
    getDaysOfTheWeekGraphForCohortSuccess(state, action) {
      state.cohortAttendance.report.cohort.daysOfWeek.data = action.payload;
      state.cohortAttendance.report.cohort.daysOfWeek.isLoading = false;
      state.cohortAttendance.report.cohort.daysOfWeek.error = null;
    },
    getDaysOfTheWeekGraphForCohortHasError(state, action) {
      state.cohortAttendance.report.cohort.daysOfWeek.error = action.payload;
      state.cohortAttendance.report.cohort.daysOfWeek.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { resetCohortAttendanceReport } = slice.actions;

// ----------------------------------------------------------------------

export function getanalyticsAsngHighlight(query, request) {
  return async () => {
    dispatch(slice.actions.startLoading({ assignhighlightLoading: true }));
    try {
      const response = await eduleyApi.get(`${ANALYTICS_ASSIGNMENT_HIGHLIGHT}${query}`, request);
      dispatch(slice.actions.getanalyticsAsngHighlightSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getanalyticsAsngOverview(query, request) {
  return async () => {
    dispatch(slice.actions.startLoading({ assignOverviewLoading: true }));
    try {
      const response = await eduleyApi.get(`${ANALYTICS_ASSIGNMENT_OVERVIEW}${query}`, request);
      dispatch(slice.actions.getanalyticsAsngOverviewSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getanalyticsQuizHighlight(query, request) {
  return async () => {
    dispatch(slice.actions.startLoading({ quizhighlightLoading: true }));
    try {
      const response = await eduleyApi.get(`${ANALYTICS_QUIZ_HIGHLIGHT}${query}`, request);
      dispatch(slice.actions.getanalyticsQuizHighlightSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getanalyticsQuizOverview(query, request) {
  return async () => {
    dispatch(slice.actions.startLoading({ quizOverviewLoading: true }));
    try {
      const response = await eduleyApi.get(`${ANALYTICS_QUIZ_OVERVIEW}${query}`, request);
      dispatch(slice.actions.getanalyticsQuizOverviewSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getanalyticsCourseHighlight(query, request) {
  return async () => {
    dispatch(slice.actions.startLoading({ coursehighlightLoading: true }));
    try {
      const response = await eduleyApi.get(`${ANALYTICS_COURSE_HIGHLIGHT}${query}`, request);
      dispatch(slice.actions.getanalyticsCourseHighlightSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getanalyticsCourseOverview(query, request) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await eduleyApi.get(`${ANALYTICS_COURSE_OVERVIEW}${query}`, request);
      dispatch(slice.actions.getanalyticscourseOverviewSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

/****************************************
 * GET COHORT ATTENDANCE ANALYTICS LIST *
 ****************************************/

/**
 * Fetches the list of cohort attendance analytics.
 *
 * @param {Object} params - The parameters for the API request.
 * @returns {Function} - The asynchronous action function.
 */
export function getCohortAttendanceAnalyticsList(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingCohortAttendanceAnalyticsList());

    try {
      const response = await eduleyApi.get(COHORT_ATTENDANCE_ANALYTICS, { params });
      const payload = {
        totalCount: response.data.count,
        data: response.data.results,
      };
      dispatch(slice.actions.getCohortAttendanceAnalyticsListSuccess(payload));
    } catch (error) {
      dispatch(slice.actions.getCohortAttendanceAnalyticsListHasError(error));
    }
  };
}

/**
 * Fetches the cohort attendance analytics highlights from the API and dispatches the appropriate actions.
 * @param {Object} params - The parameters to be sent with the API request.
 */
export function getCohortAttendanceAnalyticsHighlights(params) {
  return async (dispatch) => {
    // Dispatch the action to indicate that loading has started
    dispatch(slice.actions.startLoadingCohortAttendanceAnalyticsHighlights());

    try {
      // Make the API request to fetch the attendance analytics highlights
      const response = await eduleyApi.get(COHORT_ATTENDANCE_ANALYTICS_HIGHLIGHTS, { params });

      // Dispatch the action to store the response data
      dispatch(slice.actions.getCohortAttendanceAnalyticsHighlightsSuccess(response.data));
    } catch (error) {
      // Dispatch the action to indicate that an error occurred
      dispatch(slice.actions.getCohortAttendanceAnalyticsHighlightsHasError(error));
    }
  };
}
/**
 * Fetches the cohort attendance report cohort highlights from the API and dispatches the appropriate actions.
 * @param {Object} params - The parameters to be sent with the API request.
 */
export function getCohortAttendanceReportCohortHighlights(params) {
  return async (dispatch) => {
    // Dispatch the action to indicate that loading has started
    dispatch(slice.actions.startLoadingCohortAttendanceReportCohortHighlights());

    try {
      // Make the API request to fetch the attendance analytics highlights
      const response = await eduleyApi.get(COHORT_ATTENDANCE_ANALYTICS_HIGHLIGHTS, { params });

      // Dispatch the action to store the response data
      dispatch(slice.actions.getCohortAttendanceReportCohortHighlightsSuccess(response.data));
    } catch (error) {
      // Dispatch the action to indicate that an error occurred
      dispatch(slice.actions.getCohortAttendanceReportCohortHighlightsHasError(error));
    }
  };
}
/**
 * Fetches the cohort attendance report highlights from the API and dispatches the appropriate actions.
 * @param {Object} params - The parameters to be sent with the API request.
 */
export function getCohortAttendanceReportStudentHighlights(params) {
  return async (dispatch) => {
    // Dispatch the action to indicate that loading has started
    dispatch(slice.actions.startLoadingCohortAttendanceReportStudentHighlights());

    try {
      // Make the API request to fetch the attendance analytics highlights
      const response = await eduleyApi.get(COHORT_ATTENDANCE_ANALYTICS_HIGHLIGHTS, { params });

      // Dispatch the action to store the response data
      dispatch(slice.actions.getCohortAttendanceReportStudentHighlightsSuccess(response.data));
    } catch (error) {
      // Dispatch the action to indicate that an error occurred
      dispatch(slice.actions.getCohortAttendanceReportStudentHighlightsHasError(error));
    }
  };
}

/**
 * Fetches the date range graph for a given cohort.
 * @param {Object} params - The parameters for the API request.
 * @returns {Function} - The async action function.
 */
export function getDateRangeGraphForCohort(params) {
  return async (dispatch) => {
    // Dispatch the action to indicate that loading has started
    dispatch(slice.actions.startLoadingDateRangeGraphForCohort());

    try {
      // Make the API request to fetch the attendance analytics highlights
      const response = await eduleyApi.get(COHORT_ATTENDANCE_REPORT_ATTENDANCE_BY_RANGE, { params });

      // Dispatch the action to store the response data
      dispatch(slice.actions.getDateRangeGraphForCohortSuccess(response.data));
    } catch (error) {
      // Dispatch the action to indicate that an error occurred
      dispatch(slice.actions.getDateRangeGraphForCohortHasError(error));
    }
  };
}
/**
 * Fetches the days of the week graph for a given cohort.
 * @param {Object} params - The parameters for the API request.
 * @returns {Function} - The async action function.
 */
export function getDaysOfTheWeekGraphForCohort(params) {
  return async (dispatch) => {
    // Dispatch the action to indicate that loading has started
    dispatch(slice.actions.startLoadingDaysOfTheWeekGraphForCohort());

    try {
      // Make the API request to fetch the attendance analytics highlights
      const response = await eduleyApi.get(COHORT_ATTENDANCE_REPORT_ATTENDANCE_BY_DAYS_OF_WEEKS, { params });

      // Dispatch the action to store the response data
      dispatch(slice.actions.getDaysOfTheWeekGraphForCohortSuccess(response.data));
    } catch (error) {
      // Dispatch the action to indicate that an error occurred
      dispatch(slice.actions.getDaysOfTheWeekGraphForCohortHasError(error));
    }
  };
}
