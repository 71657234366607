import { createSlice } from '@reduxjs/toolkit';
// import axios from "axios";
import axios from '../../utils/axios';

import { ENROLLMENT_DOCUMENT_FOR_COURSE, ENROLLMENT_DOCUMENT_FOR_INSTITUTE, INSTITUTE_CONFIG, LANDING_PAGE_CONFIG } from 'src/apiUrl';
// utils
//
import { dispatch } from '../store';
import { eduleyApi } from 'src/api/apiConfig';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  instituteConfig: {},
  landingPageConfig: {},
  error: null,
  enrollmentDocument: {
    data: [],
    isLoading: false,
    error: null
  },
  hasAddDocumentOpened: false,
  selectedDocument: null
};

const slice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setInstituteConfiguration(state, action) {
      state.instituteConfig = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    setLandingPageConfiguration(state, action) {
      state.landingPageConfig = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    startEnrollmentDocumentLoading(state, action) {
      state.enrollmentDocument.isLoading = true;
      state.enrollmentDocument.error = null;
    },
    hasEnrollmentDocumentError(state, action) {
      state.enrollmentDocument.isLoading = false;
      state.enrollmentDocument.error = action.payload;
    },
    getEnrollmentDocumentSuccess(state, action) {
      state.enrollmentDocument.data = action.payload;
      state.enrollmentDocument.isLoading = false;
      state.enrollmentDocument.error = null;
    },
    setAddDocumentOpened(state, action) {
      state.hasAddDocumentOpened = action.payload;
    },
    setSelectedDocument(state, action) {
      state.selectedDocument = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setInstituteDocumentLoading, setInstituteDocument, hasError, setAddDocumentOpened, setSelectedDocument } = slice.actions;

// ----------------------------------------------------------------------

const accessToken = localStorage.getItem('accessToken');

const config = {
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`
  }
};
const config2 = {
  headers: {
    'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
    Authorization: `Bearer ${accessToken}`
  }
};
export const getInstituteConfiguration = () => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const res = await eduleyApi.get(`${INSTITUTE_CONFIG}`);
    dispatch(slice.actions.setInstituteConfiguration(res.data[0]));
    return 'success';
  } catch (error) {
    dispatch(slice.actions.hasError(error.response));
    return 'error';
  }
};

// ----------------------------------------------------------------------
export const updateInstituteConfiguaration = (data, instituteConfigId) => async (dispatch) => {
  let instituteConfigurationData = { ...data };

  let filedata = new FormData();
  for (const x in instituteConfigurationData) {
    if (instituteConfigurationData[x] != null || instituteConfigurationData[x] != '') filedata.append(x, instituteConfigurationData[x]);
  }
  dispatch(slice.actions.startLoading());
  try {
    const res = await axios.patch(`${INSTITUTE_CONFIG}${instituteConfigId}/`, filedata, config2);
    dispatch(slice.actions.setInstituteConfiguration(res.data));
    return 'success';
  } catch (error) {
    dispatch(slice.actions.hasError(error.response.data));
    return 'error';
  }
};

export const getLandingPageConfiguration = () => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const res = await eduleyApi.get(`${LANDING_PAGE_CONFIG}`);
    dispatch(slice.actions.setLandingPageConfiguration(res.data[0]));
    return 'success';
  } catch (error) {
    dispatch(slice.actions.hasError(error.response));
    return 'error';
  }
};
export const updateLandingPageConfiguaration = (data, landingPageConfigId) => async (dispatch) => {
  console.log('dsaf', landingPageConfigId, data);
  dispatch(slice.actions.startLoading());
  try {
    let res;
    if (landingPageConfigId != undefined) {
      console.log('dsaf  patch', landingPageConfigId, data);
      res = await axios.patch(`${LANDING_PAGE_CONFIG}${landingPageConfigId}/`, data, config);
    } else {
      console.log('dsaf patch', landingPageConfigId, data);
      res = await axios.post(`${LANDING_PAGE_CONFIG}`, data, config);
    }
    dispatch(slice.actions.setLandingPageConfiguration(res.data));
    return 'success';
  } catch (error) {
    dispatch(slice.actions.hasError(error?.response?.data));
    return 'error';
  }
};

/**
 * Retrieves enrollment documents based on the provided params.
 * @param {string} params - The params to filter the documents.
 */
export const getEnrollmentDocuments = (params) => async (dispatch) => {
  // Start loading state
  dispatch(slice.actions.startEnrollmentDocumentLoading());

  try {
    // Make API request to retrieve documents
    const res = await eduleyApi.get(ENROLLMENT_DOCUMENT_FOR_COURSE, { params });
    // Dispatch success action with retrieved data
    dispatch(slice.actions.getEnrollmentDocumentSuccess(res.data));
  } catch (error) {
    // Dispatch error action with error data
    dispatch(slice.actions.hasEnrollmentDocumentError(error.data));
  }
};
export const getEnrollmentDocumentsForInstitute = (params) => async (dispatch) => {
  // Start loading state
  dispatch(slice.actions.startEnrollmentDocumentLoading());

  try {
    // Make API request to retrieve documents
    const res = await eduleyApi.get(ENROLLMENT_DOCUMENT_FOR_INSTITUTE, { params });
    // Dispatch success action with retrieved data
    dispatch(slice.actions.getEnrollmentDocumentSuccess(res.data));
  } catch (error) {
    // Dispatch error action with error data
    dispatch(slice.actions.hasEnrollmentDocumentError(error.data));
  }
};