import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "../../utils/axios";

import { INSTRUCTOR, USER_LIST, USER_ROLE } from "src/apiUrl";
// utils
//
import { dispatch } from "../store";
import { eduleyApi } from "src/api/apiConfig";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  users: {},
  user: {},
  instructor: {},
  error: null,
  rolelist: [],
  sortBy: null,

  filters: {
    category: "All",
    rating: "",
  },
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    setRolelist(state, action) {
      state.isLoading = false;

      state.rolelist = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setUserList(state, action) {
      state.users = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    setUser(state, action) {
      state.user = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    setInstructor(state, action) {
      state.instructor = action.payload;
      state.isLoading = false;
      state.error = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { } = slice.actions;

// ----------------------------------------------------------------------

const accessToken = localStorage.getItem("accessToken");

export const getUserList = (page) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const res = await eduleyApi.get(`${USER_LIST}${page}`);
    dispatch(slice.actions.setUserList(res.data));
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error.response));
    return "error";
  }
};

// ----------------------------------------------------------------------
export const adduser = (data) => async (dispatch) => {
  let data2 = { ...data };

  delete data2.groups;
  if (!data2.profile_photo) {
    delete data2.profile_photo;
  }

  let filedata = new FormData();
  for (const x in data2) {
    filedata.append(x, data2[x]);
  }

  for (const x in data.groups) {
    filedata.append(`groups`, data.groups[x]);
  }
  dispatch(slice.actions.startLoading());
  try {
    await eduleyApi.post(`${USER_LIST}`, filedata);
    // dispatch(slice.actions.setUser(res.data));
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error.response.data));
    return "error";
  }
};
export const deleteuser = (data) => async (dispatch) => {
  try {
    await eduleyApi.delete(`${USER_LIST}${data}/`);

    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error.response.data));
    return "error";
  }
};

export const getRolelist = () => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const res = await eduleyApi.get(`${USER_ROLE}`);
    console.log("res", res);
    dispatch(slice.actions.setRolelist(res.data));
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error.response.data));
    return "error";
  }
};

export const updateUser = (data) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  console.log("data", data);
  console.log("data type--", typeof data.profile_photo);
  let data2 = { ...data };
  if (data.profile_photo) {
    delete data2.groups;
  }
  let filedata = new FormData();
  for (const x in data2) {
    filedata.append(x, data2[x]);
  }
  for (const x in data.groups) {
    filedata.append(`groups`, data.groups[x]);
  }

  try {
    if (data.profile_photo) {
      await eduleyApi.put(`${USER_LIST}${data.id}/`, filedata);
    } else {
      await eduleyApi.put(`${USER_LIST}${data.id}/`, data);
    }
    // dispatch(slice.actions.setUser(res.data));
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error.response.data));
    return "error";
  }
};

export const getUser = (id) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const res = await eduleyApi.get(`${USER_LIST}${id}/`);
    dispatch(slice.actions.setUser(res.data));
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error.response.data));
    return "error";
  }
};

export const getInstructorList = (query) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  console.log("query", query);
  try {
    const res = await eduleyApi.get(`${INSTRUCTOR}${query}`);
    dispatch(slice.actions.setInstructor(res.data));
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error.response.data));
    return "error";
  }
};
export const getInstructor = (id) => async (dispatch) => {
  if (id == "") {
    return;
  }
  try {
    const res = await eduleyApi.get(`${INSTRUCTOR}${id}/`);
    return res.data;
  } catch (error) {
    // console.log(error);
    dispatch(slice.actions.hasError(error.data));
    return "error";
  }
};

export const deleteInstructor = (data) => async (dispatch) => {
  try {
    await eduleyApi.delete(`${INSTRUCTOR}${data}/`);
    return "success";
  } catch (error) {
    dispatch(slice.actions.hasError(error.response.data));
    return "error";
  }
};
export const updateInstructor = (query) => async (dispatch) => {
  try {
    const res = await eduleyApi.patch(`${INSTRUCTOR}${query.id}/`, query);
    return "success";
  } catch (error) {
    return "error";
  }
};
