import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Container,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { eduleyApi } from "src/api/apiConfig";
import { ASSIGNMENT_TRACK, ASSIGNMENT_TRACK_REVIEW } from "src/apiUrl";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Iconify from "src/components/Iconify";
import IconifyButton from "src/components/IconifyButton";
import Label from "src/components/Label";
import LoadingIcon from "src/components/LoadingIcon";
import Markdown from "src/components/Markdown";
import Page from "src/components/Page";
import Scrollbar from "src/components/Scrollbar";
import { TableHeadCustom } from "src/components/table";
import useSettings from "src/hooks/useSettings";
import { useDispatch } from "src/redux/store";
import { PATH_DASHBOARD } from "src/routes/paths";
import MediaViewer from "src/sections/media/mediaViewer/MediaViewer";

const TABLE_HEAD = [
  { id: "id", label: "No.", alignRight: false, width: 50 },
  { id: "id", label: "Questions ", alignRight: false, width: 300 },
  { id: "", label: "Answer ", alignRight: false, width: 80 },

  { id: "", label: "Mark Obtained ", alignRight: false, width: 100 },
  { id: "", label: "Total Marks ", alignRight: false, width: 100 },
];
const AssignmentQuestionReview = () => {
  const { themeStretch } = useSettings();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    getAssignmentData();
  }, []);
  const getAssignmentData = async () => {
    setLoading(true);
    try {
      let res = await eduleyApi.get(`${ASSIGNMENT_TRACK}${params.id}/`);
      if (!_.isEmpty(res.data)) {
        setData(res.data);
      }
    } catch (error) {}
    setLoading(false);
  };
  const markQuestion = data?.answers?.filter((a) =>
    a.marks_obtained ? a.marks_obtained >= 0 : false
  )?.length;
  const unMarkedQuestion = data?.answers?.filter(
    (a) => !a.marks_obtained
  )?.length;
  return (
    <Page title="Assignment Tracking">
      <Container maxWidth="xl">
        {/* <Button
          onClick={() => navigate(-1)}
          startIcon={
            <Iconify icon={"eva:arrow-ios-back-fill"} width={20} height={20} />
          }
        >
          Back
        </Button> */}
        <HeaderBreadcrumbs
          heading={<>Assignment Review</>}
          links={[
            {
              name: "Assignment List",
              href: PATH_DASHBOARD.track.assignment,
            },
            { name: data?.student_name },
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Scrollbar
              sx={{
                height: "80vh",
                p: 2,
              }}
            >
              {data?.answers?.map((a, index) => (
                <QuestionRow
                  key={a.id + "questionRow"}
                  data={a}
                  index={index}
                  handleUpdate={getAssignmentData}
                />
              ))}
            </Scrollbar>
          </Grid>

          <Grid item xs={3} sx={{ pt: 2 }}>
            <Stack direction="row" spacing={2} justifyContent={"space-between"}>
              <Typography variant="body2">Current Status</Typography>
              <Label
                variant={theme.palette.mode === "light" ? "ghost" : "filled"}
                color={
                  (data?.status === "need to resubmit" && "error") ||
                  (data?.status === "submitted" && "warning") ||
                  (data?.status === "awaiting marking" && "info") ||
                  "success"
                }
                sx={{ textTransform: "capitalize" }}
              >
                {data.status}
              </Label>
            </Stack>
            {/* <InfoCard
              title="Total Given Marks"
              value={`${data.marks || "_"}/${data?.total_marks}`}
            /> */}
            <Card sx={{ mt: 2 }}>
              <CardContent>
                <Stack
                  sx={{ mt: 1 }}
                  direction={"row"}
                  justifyContent={"space-between"}
                  spacing={2}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: 24, fontWeight: "bold" }}
                    color="text.secondary"
                  >
                    {data.marks || "_"}

                    {`/${data?.total_marks}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ fontSize: 24, fontWeight: "bold" }}
                  >
                    {data?.percentage_scored || 0}%
                  </Typography>
                </Stack>
                <Stack sx={{ mt: 1 }} />
                <Typography variant="subtitle">Total Given Marks</Typography>
              </CardContent>
            </Card>
            <InfoCard title="Marked Question" value={markQuestion} />
            <InfoCard title="Unmarked Question" value={unMarkedQuestion} />
            <InfoCard title="Total Question" value={data?.answers?.length} />
            {/* <Stack sx={{ position: "absolute", bottom: 20 }}>
              <Button
                variant="contained"
                fullWidth
                size="large"
                startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
                onClick={() => navigate(-1)}
              >
                go back to list
              </Button>
            </Stack> */}
          </Grid>
        </Grid>
        <CardActions>
          {/* <Button onClick={() => navigate(-1)}>Back</Button> */}
        </CardActions>
      </Container>
    </Page>
  );
};

const InfoCard = ({ title, value }) => {
  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Typography
          variant="body2"
          sx={{ fontSize: 24, fontWeight: "bold" }}
          color="text.secondary"
        >
          {value}
        </Typography>
        <Stack sx={{ mt: 1 }} />
        <Typography variant="subtitle">{title}</Typography>
      </CardContent>
    </Card>
  );
};

export default AssignmentQuestionReview;

const QuestionRow = ({ data, handleUpdate, index }) => {
  const { question, subjective_answer, marks_obtained } = data;
  const [loading, setLoading] = useState(false);

  const { name, maximum_marks, attachment: attechment } = question;
  const { enqueueSnackbar } = useSnackbar();
  const [openAnswer, setOpenAnswer] = useState(false);
  const handleAnswerView = () => {
    setOpenAnswer(!openAnswer);
  };
  const [value, setValue] = useState({
    marks: marks_obtained,
    remarks: data?.remarks,
  });
  useEffect(() => {
    console.log(
      "marks_obtained",
      +marks_obtained,
      index,
      marks_obtained != null
    );
    if (marks_obtained != null) {
      setOpenAnswer(true);
    } else {
      setOpenAnswer(false);
    }
  }, []);
  const handleChangeValue = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };
  const handleSave = async () => {
    if (!value.marks) {
      enqueueSnackbar("Please enter marks", { variant: "error" });
      return;
    }
    if (value.marks > maximum_marks) {
      enqueueSnackbar("Marks cannot be greater than maximum marks", {
        variant: "error",
      });
      return;
    }
    setLoading(true);
    try {
      let res = await eduleyApi.patch(`${ASSIGNMENT_TRACK_REVIEW}${data.id}/`, {
        // ...data,
        marks_obtained: value.marks,
        remarks: value.remarks,
        // attempt: data.attempt,
      });
      console.log(res);
      handleAnswerView();
      handleUpdate();
    } catch (error) {
      console.log("object", error);
    }
    setLoading(false);
  };
  console.log("daf----", data);
  return (
    <>
      <Card sx={{ mb: 3 }}>
        <CardHeader
          // title={<>Question {index + 1}</>}
          avatar={<></>}
          subheader={
            <>
              <Stack direction={"row"} spacing={2}>
                <Avatar>{index + 1}</Avatar>
                <Typography
                  variant="subtitle"
                  sx={{ fontSize: 16, fontWeight: 300 }}
                  color="text.primary"
                >
                  {name}
                </Typography>
                {attechment && (
                  <MediaViewer
                    file={attechment}
                    action={
                      <img
                        title={name}
                        className="thumbnail"
                        src={attechment?.url || attechment?.media_file}
                        style={{
                          "max-height": "165px",
                          "max-width": "165px",
                          borderRadius: "10px",
                        }}
                      />
                    }
                  />
                )}
              </Stack>
            </>
          }
        />
        <Divider textAlign="left" sx={{ mt: 3 }}>
          <Chip label="Answer" />
        </Divider>
        <CardContent>
          {/* <Typography variant="h6">Answer</Typography> */}

          <Box
            sx={{
              // img tag should be wrapped in a div
              "& img": {
                width: "auto",
                height: "auto",
              },
            }}
          >
            <Markdown children={subjective_answer} />
          </Box>
        </CardContent>
        <Divider />
        <CardActions>
          <Stack direction={"column"} sx={{ width: "100%", p: 2 }} spacing={2}>
            {openAnswer && (
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
              >
                <Stack direction={"row"} spacing={2}>
                  <Typography variant="subtitle" color="text.primary">
                    Mark Given: {+value.marks >= 0 ? value.marks : "_"},
                  </Typography>

                  <Typography variant="subtitle" color="text.primary">
                    Maximum Marks: {maximum_marks}
                  </Typography>
                </Stack>
                <Button
                  onClick={handleAnswerView}
                  startIcon={<Iconify icon="eva:edit-fill" />}
                >
                  Edit Mark
                </Button>
              </Stack>
            )}
            {!openAnswer && (
              <Stack
                direction="row"
                justifyContent={"flex-end"}
                sx={{ width: "100%", pb: 2 }}
                spacing={1}
              >
                <TextField
                  label="Enter Remarks"
                  name="remarks"
                  value={value.remarks}
                  onChange={handleChangeValue}
                  fullWidth
                  size="large"
                  sx={{ height: 50 }}
                  inputProps={{
                    style: {
                      height: 25,
                    },
                  }}
                />
                <TextField
                  label="Enter Marks"
                  type="number"
                  name="marks"
                  sx={{ width: 400, height: 50 }}
                  inputProps={{
                    style: {
                      fontSize: 18,
                      height: 25,
                    },
                    min: 0,
                    max: maximum_marks,
                  }}
                  value={value.marks}
                  onChange={handleChangeValue}
                  error={value.marks > maximum_marks}
                  helperText={
                    (value.marks > maximum_marks &&
                      "Max marks limit exceeded") || (
                      <>
                        Marks cannot be greater than <b>{maximum_marks}</b>
                      </>
                    )
                  }
                />
                <Stack>
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    size="large"
                    sx={{
                      width: 150,
                      height: 57,
                    }}
                    onClick={handleSave}
                  >
                    Save
                  </LoadingButton>
                </Stack>
              </Stack>
            )}
          </Stack>
        </CardActions>
      </Card>
    </>
  );
};
