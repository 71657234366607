import { createSlice } from "@reduxjs/toolkit";
import axios from "../../../src/utils/axios";
import sum from "lodash/sum";
import uniqBy from "lodash/uniqBy";
import { useSnackbar } from "notistack";
import { COHORT, COURSE_LIST } from "src/apiUrl";
// utils
//
import { dispatch } from "../store";
import { eduleyApi } from "src/api/apiConfig";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  course: {},
  error: null,
  // products: [],
  // product: null,
  sortBy: null,
  filters: {
    // gender: [],
    category: "All",
    // colors: [],
    priceRange: "",
    rating: "",
  },
  cohortlist: {},
  cohortdetails: null,
};

const slice = createSlice({
  name: "product",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getCoursesSuccess(state, action) {
      state.isLoading = false;
      const products2 = action.payload;
      state.course = products2;
    },

    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },
    setcohortlist(state, action) {
      state.cohortlist = action.payload;
    },
    setCohortDetails(state, action) {
      state.cohortdetails = action.payload;
      state.isLoading = false;
    },
    getCohortDetailsLoading(state, action) {
      state.isLoading = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  onGotoStep,
  onBackStep,
  onNextStep,

  sortByProducts,
  // filterProducts,
} = slice.actions;

// ----------------------------------------------------------------------

const accessToken = localStorage.getItem("accessToken");

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  },
};

// const getStatus = (status) => {
//   console.log("status", status);

//   switch (status.status) {
//     case 401:
//       window.location.href = "/auth/login";
//       return "Unauthorized";
//     default:
//       return "";
//   }
// };

export function getCourses(q) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await eduleyApi.get(`${COURSE_LIST}${q}`);

      dispatch(slice.actions.getCoursesSuccess(response.data));
    } catch (error) {
      // getStatus(error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getCourse(q) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await eduleyApi.get(`${COURSE_LIST}${q}/`);
      return response.data;
    } catch (error) {
      // getStatus(error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCourse(q) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await eduleyApi.patch(`${COURSE_LIST}${q.id}/`, q,);
      // dispatch(slice.actions.getCoursesSuccess(response.data));
      console.log("response", response.data);
      return "success";
    } catch (error) {
      // getStatus(error.response);
      // console.log("error", error.response);
      // dispatch(slice.actions.hasError(error));
      return error.response;
    }
  };
}

// ----------------------------------------------------------------------
export function setCohortDataNull() {
  return async () => {
    dispatch(slice.actions.setCohortDetails(null));
  };
}
export function getCohort(id) {
  return async () => {
    dispatch(slice.actions.getCohortDetailsLoading());
    // dispatch(slice.actions.startLoading());
    try {
      const response = await eduleyApi.get(`${COHORT}${id}/`);
      dispatch(slice.actions.setCohortDetails(response.data));
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error.data);
    }
  };
}
export function getCohortlist(q) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await eduleyApi.get(`${COHORT}${q}`);

      dispatch(slice.actions.setcohortlist(response.data));
    } catch (error) {
      // getStatus(error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function createCohort(q) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await eduleyApi.post(`${COHORT}`, q);
      // console.log("response", response.data);
      return Promise.resolve(response);
    } catch (error) {
      console.log(error);

      // getStatus(error.response);
      return Promise.reject(error.data);
    }
  };
}
export function updateCohort(q) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await eduleyApi.patch(`${COHORT}${q.id}/`, q);
      dispatch(slice.actions.setCohortDetails(response.data));
      return Promise.resolve(response);
    } catch (error) {
      // getStatus(error.response);
      return Promise.reject(error.data);
    }
  };
}
