import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "../../utils/axios";

import {
  EDULEY_SUPPORT,
  EDULEY_SUPPORT_REPLY,
  STUDENT_SUPPORT,
  STUDENT_SUPPORT_REPLY,
} from "src/apiUrl";
// utils
//
import { dispatch } from "../store";
import { eduleyApi } from "src/api/apiConfig";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  eduleysupport: {},
  error: null,
  converstion: {},
  studentsupport: {},
  stuconverstion: {},
  // studentsupport: {},
};

const slice = createSlice({
  name: "support",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setEduleySupport(state, action) {
      state.eduleysupport = action.payload;
      state.isLoading = false;
    },
    setConverstion(state, action) {
      state.converstion = action.payload;
      state.isLoading = false;
    },
    setEduleySupportforStu(state, action) {
      state.studentsupport = action.payload;
      state.isLoading = false;
    },
    setConverstionforStu(state, action) {
      state.stuconverstion = action.payload;
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { } = slice.actions;

// ----------------------------------------------------------------------

const accessToken = localStorage.getItem("accessToken");

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  },
};
const config2 = {
  headers: {
    "Content-Type":
      "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
    Authorization: `Bearer ${accessToken}`,
  },
};

export function getEduleySupport(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await eduleyApi.get(`${EDULEY_SUPPORT}${data}`);

      dispatch(slice.actions.setEduleySupport(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export const createTicket = (data) => async (dispatch) => {
  console.log("data", data);
  let filedata = new FormData();
  for (const x in data) {
    filedata.append(x, data[x]);
  }
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(`${EDULEY_SUPPORT}`, filedata, config2);
    // dispatch(slice.actions.setEduleySupport(response.data));
    return "success";
  } catch (error) {
    console.error(error);
    dispatch(slice.actions.hasError(error));
    return "error";
  }
};

export const getConversations = (data) => async (dispatch) => {
  console.log("data", data);
  dispatch(slice.actions.startLoading());
  try {
    const response = await eduleyApi.get(`${EDULEY_SUPPORT}${data}/`);
    dispatch(slice.actions.setConverstion(response.data));
    return "success";
  } catch (error) {
    console.error(error);
    dispatch(slice.actions.hasError(error));
    return "error";
  }
};

export const replyTicket = (id, data) => async (dispatch) => {
  console.log('ticket--', data)
  dispatch(slice.actions.startLoading());
  try {
    const response = await eduleyApi.post(
      `${EDULEY_SUPPORT_REPLY}`,
      data,

    );
    dispatch(getConversations(id));
    return "success";
  } catch (error) {
    console.error(error);
    dispatch(slice.actions.hasError(error));
    return "error";
  }
};

export const changeTicket = (data) => async (dispatch) => {
  console.log("data", data);
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.patch(
      `${EDULEY_SUPPORT}${data.id}/`,
      data,
      config
    );
    dispatch(getConversations(data.id));
    return "success";
  } catch (error) {
    console.error(error);
    dispatch(slice.actions.hasError(error));
    return "error";
  }
};
// Student
export function getEduleySupportforStu(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await eduleyApi.get(`${STUDENT_SUPPORT}${data}`);

      dispatch(slice.actions.setEduleySupportforStu(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export const createTicketforStu = (data) => async (dispatch) => {
  console.log("data", data);
  // let filedata = new FormData();
  // for (const x in data) {
  //   filedata.append(x, data[x]);
  // }
  // dispatch(slice.actions.startLoading());
  // try {
  //   const response = await axios.post(`${STUDENT_SUPPORT}`, filedata, config2);
  //   // dispatch(slice.actions.setEduleySupport(response.data));
  //   return "success";
  // } catch (error) {
  //   console.error(error);
  //   dispatch(slice.actions.hasError(error));
  //   return "error";
  // }
};

export const getConversationsforStu = (data) => async (dispatch) => {
  console.log("data", data);
  dispatch(slice.actions.startLoading());
  try {
    const response = await eduleyApi.get(`${STUDENT_SUPPORT}${data}/`);
    dispatch(slice.actions.setConverstionforStu(response.data));
    return "success";
  } catch (error) {
    console.error(error);
    dispatch(slice.actions.hasError(error));
    return "error";
  }
};

export const replyTicketforStu = (id, data) => async (dispatch) => {
  console.log("data", data);
  let filedata = new FormData();
  for (const x in data) {
    filedata.append(x, data[x]);
  }
  //   formdata.append("message", data.message);

  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      `${STUDENT_SUPPORT_REPLY}`,
      filedata,
      config2
    );
    dispatch(getConversationsforStu(id));
    return "success";
  } catch (error) {
    console.error(error);
    dispatch(slice.actions.hasError(error));
    return "error";
  }
};

export const changeTicketforStu = (data) => async (dispatch) => {
  console.log("data", data);
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.patch(
      `${STUDENT_SUPPORT}${data.id}/`,
      data,
      config
    );
    dispatch(getConversationsforStu(data.id));
    return "success";
  } catch (error) {
    console.error(error);
    dispatch(slice.actions.hasError(error));
    return "error";
  }
};
